<!-- eslint-disable vue/valid-v-slot -->
<template>
  <v-data-table-server
    v-model:items-per-page="itemsPerPage"
    class="narrow-borders"
    :items="serverItems"
    :items-length="totalItems || 0"
    :headers="selectedHeadersComputed"
    :search="search"
    :loading="loading"
    :mobile="showExpanded"
    :multi-sort="true"
    item-value="name"
    @update:page="updatePage"
    @update:sort-by="updateSortBy"
    @update:options="loadItems"
  >
    <template #top>
      <v-toolbar
        flat
        density="compact"
      >
        <v-toolbar-items>
          <v-dialog
            v-if="createEnabled"
            v-model="dialog"
            :width="!xs ? 'auto' : '100000px'"
            :fullscreen="xs"
          >
            <template #activator="{ props }">
              <v-btn
                color="primary"
                v-bind="props"
                icon="fas fa-plus"
              />
            </template>
            <component
              :is="editionComponent"
              mode="create"
              @model:created="loadItems"
              @close="dialog = false;"
            />
          </v-dialog>
        </v-toolbar-items>
        <v-toolbar-items class="w-50 d-flex align-center">
          <v-text-field
            v-if="searchEnabled"
            v-model="tempSearchText"
            class="ms-2"
            flat
            clearable
            density="compact"
            :label="t('global.search')"
            variant="solo"
            hide-details
            @click:clear="searchModel"
            @input="searchModel"
          >
            <template #prepend-inner>
              <v-icon
                icon="fas fa-search"
                size="x-small"
              />
            </template>
          </v-text-field>
        </v-toolbar-items>
        <v-spacer />
        <slot name="globalActions" />
        <v-icon
          color="primary"
          :icon="showExpanded ? 'fas fa-caret-up' : 'fas fa-caret-down'"
          @click="showExpanded = !showExpanded"
        />
        <v-toolbar-items>
          <vuetify-data-table-column-select
            v-if="columnSelectionEnabled"
            v-model="selectedHeaders"
            :headers="headers"
          />
        </v-toolbar-items>
      </v-toolbar>
    </template>

    <template #item.subpoints="{ item }">
      <ul>
        <li
          v-for="(itm, index) in item.subpoints"
          :key="index"
        >
          {{ itm }}
        </li>
      </ul>
    </template>

    <template #item.tags="{ item }">
      <div
        v-if="
          /* @ts-ignore*/
          !item.tags.length"
      >
        <span class="text-grey">{{ t('tags.no_tags') }}</span>
      </div>
      <div v-else>
        <v-chip
          v-for="tag in item.tags"
          :key="tag"
        >
          {{ tag }}
        </v-chip>
      </div>
    </template>

    <template #item.profile_image_url="{ item }">
      <user-avatar
        :user="item"
        size="35px"
        class="ma-1"
      />
    </template>

    <template #item.icon="{ item }">
      <v-icon :icon="`fas fa-${item.icon}`" />
    </template>

    <template
      v-for="header in customColumns /*@ts-ignore*/"
      #[`item.${header}`]="{ item }"
    >
      <slot
        :name="`item.${header}`"
        v-bind="{ item }"
      />
    </template>

    <template #item.actions="{ item }">
      <v-toolbar
        density="compact"
        flat
        color="white"
      >
        <v-toolbar-items class="w-100">
          <component
            :is="action.component"
            v-for="(action, $index) in additionalActions"
            v-bind="action.props"
            :key="$index"
            :model="item"
            @reload="loadItems"
            @click="runCallback(action, item)"
          />
          <slot
            name="actions"
            v-bind="{ item, loadItems }"
          />
          <v-dialog
            v-if="editEnabled"
            :width="!xs ? 'auto' : undefined"
            :fullscreen="xs"
          >
            <template #activator="{ props: btnProps }">
              <v-btn
                v-tooltip="'Edit'"
                color="primary"
                v-bind="btnProps"
                icon="fa fa-pen-to-square"
                :text="t('actions.edit')"
                :title="t('actions.edit')"
              />
            </template>

            <template #default="{ isActive }">
              <component
                :is="editionComponent"
                :model-id="item.id"
                mode="edit"
                @model:updated="loadItems"
                @close="isActive.value = false"
              />
            </template>
          </v-dialog>
          <deletion-dialog
            v-if="deleteEnabled"
            :model-name="modelName"
            :delete-url="getDeletionUrl(item?.id)"
            size="small"
            @model:deleted="loadItems"
          />
        </v-toolbar-items>
      </v-toolbar>
    </template>
  </v-data-table-server>
</template>

<script setup lang="ts">
import type { AxiosError } from 'axios';
import UserAvatar from '@/components/images/UserAvatar.vue';
import { useDataTableUtilities } from '@/composables/index.ts';
import { useErrorStore } from '@/stores/errors';
import axios from 'axios';
import debounce from 'lodash/debounce';
import {
  computed,
  ref,
} from 'vue';
import { useI18n } from 'vue-i18n';
import { useDisplay } from 'vuetify';
import DeletionDialog from './DeletionDialog.vue';
import VuetifyDataTableColumnSelect from './VuetifyDataTableColumnSelect.vue';

const properties = withDefaults(defineProps<{
  url: string;
  headers: Array<any>;
  modelName: string;
  createEnabled?: boolean;
  editEnabled?: boolean;
  deleteEnabled?: boolean;
  searchEnabled?: boolean;
  columnSelectionEnabled?: boolean;
  editionComponent?: object;
  filters?: Array<SearchFilter>;
  includes?: Array<string>;
  additionalActions?: Array<any>;
  customColumns?: Array<string>;
}>(), {
  createEnabled: () => true,
  editEnabled: () => true,
  deleteEnabled: () => true,
  columnSelectionEnabled: () => true,
  searchEnabled: () => true,
  filters: () => [],
  includes: () => [],
  customColumns: () => [],
});
const { xs } = useDisplay();
const { t } = useI18n();

const showExpanded = ref(false);

const { showSnackMessage } = useErrorStore();
const { getDataTableUrl } = useDataTableUtilities();

type ServerItem = App.Models.User & {
  [key: string]: any;
  subpoints?: string;
  icon?: string;
  order?: number;
};

function getDeletionUrl(itemId) {
  const temp = properties.url.split('?')[0].replace(/\/$/, '');
  return `${temp}/${itemId}`;
}

const selectedHeaders = ref<any>([]);
const totalItems = ref(0);
const dialog = ref(false);
const loading = ref(true);
const search = ref('');
const serverItems = ref<ServerItem[]>([]);
const currentPage = ref(1);
const itemsPerPage = ref(25);
const sortBy = ref();
const tempSearchText = ref('');

const savedSearch = sessionStorage.getItem(`${properties.url}search`);
if (savedSearch) {
  search.value = savedSearch;
  tempSearchText.value = search.value;
}

const searchModel = debounce(() => {
  search.value = tempSearchText.value;
  sessionStorage.setItem(`${properties.url}search`, search.value);
}, 500);

function runCallback(action, item) {
  if (action.callback) {
    action.callback(item);
  }
}

function updatePage(page) {
  currentPage.value = page;
}

function updateSortBy(sort) {
  sortBy.value = sort;
}

async function loadItems() {
  loading.value = true;

  const url = getDataTableUrl(
    properties.url,
    properties.includes,
    itemsPerPage.value,
    currentPage.value,
    search.value,
    [],
    sortBy.value,
    null,
    properties.filters,
  );
  try {
    const response = await axios.get(url);
    totalItems.value = response.data.total ?? response.data.meta.total;
    serverItems.value = response.data.data;
  }
  catch (error) {
    const errors = error as Error | AxiosError;
    let errorMessage = t('errors.cannot_load');
    if (axios.isAxiosError(errors)) {
      errorMessage = errors?.response?.data?.message;
    }
    showSnackMessage(errorMessage);
  }
  finally {
    loading.value = false;
  }
}

selectedHeaders.value = properties.headers.map((header) => header.key);
const selectedHeadersComputed = computed(() => properties.headers.filter((header) => selectedHeaders.value.includes(header.key)));

defineExpose({ loadItems });
</script>

<!--
You can use a template, as per the below example, to define the global actions for the table.
   <template #globalActions>
      <v-toolbar-items class="d-flex align-center">
        <v-select
          v-model="state"
          density="compact"
          hide-details
          :items="states"
          variant="plain"
          class="mb-2"
          @update:model-value="reload"
        />
        <v-menu :close-on-content-click="false">
          <template #activator="{ props: btnProps }">
            <v-btn
              v-bind="btnProps"
              icon="fas fa-filter"
              color="grey-darken-1"
            />
          </template>
          <v-card min-width="300">
            <v-card-text>
              <v-switch
                v-model="onlyArchived"
                :label="t('experience.show_archived')"
                color="primary"
                hide-details
                @update:model-value="reload"
              />
              <v-switch
                v-model="assigned"
                :label="t('experience.assigned_to_me')"
                color="primary"
                hide-details
                @update:model-value="reload"
              />
            </v-card-text>
          </v-card>
        </v-menu>
      </v-toolbar-items>
    </template> --> -->
